import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Layout } from "../components/Layout";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PropTypes from "prop-types";
import BAAITS from "../files/experience/BAAITS.png";
import AISES from "../files/experience/AISES.png";
import Pinterest from "../files/experience/Pinterest.png";
import NationalMerit from "../files/experience/NationalMerit.jpg";
import CyberSkyline from "../files/experience/CyberSkyline.png";
import OU from "../files/experience/OU.png";
import Chevron from "../files/experience/Chevron.jpg";
import GEAviation from "../files/experience/GEAviation.png";
import PIndigenous from "../files/experience/PIndigenous.png";

function ListItemExperience({
  title,
  company,
  image,
  altText,
  children,
  state,
}) {
  const [isOpen, setIsOpen] = useState(state ?? false);

  const onClick = (event) => {
    setIsOpen(!isOpen);
    event.preventDefault();
    event.stopPropagation();
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Space") {
      setIsOpen(!isOpen);
      event.preventDefault();
    }
  };

  return (
    <>
      <ListItem onClick={onClick} onKeyDown={onKeyDown} className="cvList">
        <ListItemText primary={title} secondary={company} />
        {image && (
          <img
            src={image}
            alt={altText}
            style={{
              width: "max(16vw, 125px)",
              verticalAlign: "middle",
            }}
          />
        )}
        <ListItemIcon
          aria-label={isOpen ? `Close ${title}` : `Open ${title}`}
          style={{ display: "flex", justifyContent: "center" }}
          tabIndex={0}
        >
          {isOpen ? (
            <ExpandLess sx={{ fontSize: "max(4vw, 20px) !important" }} />
          ) : (
            <ExpandMore sx={{ fontSize: "max(4vw, 20px) !important" }} />
          )}
        </ListItemIcon>
      </ListItem>
      <Collapse in={isOpen} className="sectionStyle">
        {children}
      </Collapse>
    </>
  );
}

ListItemExperience.propTypes = {
  title: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  image: PropTypes.node,
  altText: PropTypes.string,
  children: PropTypes.node,
  state: PropTypes.bool,
};

function BulletList({ children }) {
  return (
    <List
      sx={{
        listStyleType: "disc",
        listStylePosition: "inside",
      }}
    >
      {children}
    </List>
  );
}

BulletList.propTypes = {
  children: PropTypes.node,
};

function BulletListItem({ children }) {
  return (
    <ListItem
      sx={{ display: "list-item", fontSize: "max(1.6vw, 16px) !important" }}
    >
      {children}
    </ListItem>
  );
}

BulletListItem.propTypes = {
  children: PropTypes.node,
};

function CV() {
  const [tabState, setTabState] = useState(1);

  const setTab = (event, newValue) => {
    setTabState(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Experience and Resume</title>
        <meta
          name="description"
          content="A look into my work experience, volunteer work, and achievements"
        />

        <meta property="og:title" content="Experience" />
        <meta property="og:url" content="https://brodiegullic.com/experience" />
        <meta
          property="og:description"
          content="A look into my work experience, volunteer work, and achievements"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="../files/OpenGraph.png" />

        <link rel="canonical" href="https://www.brodiegullic.com/experience" />
      </Helmet>
      <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
        <p className="titleText">EXPERIENCE</p>
        <div
          style={{ display: "flex", justifyContent: "end", marginBottom: 10 }}
        >
          <Button
            variant="contained"
            href="/Resume.pdf"
            target="_blank"
            size="large"
            endIcon={
              <OpenInNewIcon sx={{ fontSize: "max(1.6vw, 16px) !important" }} />
            }
            sx={{ fontSize: "max(1.6vw, 16px)" }}
          >
            Resume
          </Button>
        </div>
        <div
          style={{
            marginBottom: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            onChange={setTab}
            value={tabState}
            aria-label="Selectable types of experience"
            variant="scrollable"
          >
            <Tab label="Professional" value={1} />
            <Tab label="Volunteer" value={2} />
            <Tab label="Academic" value={3} />
            <Tab label="Accolades" value={4} />
          </Tabs>
        </div>
        {tabState === 1 && (
          <List>
            <ListItemExperience
              title="Software Engineer II"
              company="Pinterest | June 2023 - Present"
              image={Pinterest}
              altText="Pinterest Logo"
            >
              <BulletList>
                <BulletListItem>
                  Develop and maintain the Pinterest ad creation and editing
                  tools
                </BulletListItem>
                <BulletListItem>
                  Lead and shape the vision for projects, work with product,
                  data, and design to manage expectations
                </BulletListItem>
                <BulletListItem>
                  Mentor and support team members on technical expertise and
                  career growth
                </BulletListItem>
              </BulletList>
            </ListItemExperience>
            <ListItemExperience
              title="Pinterest Indigenous ERG Lead"
              company="Pinterest | January 2023 - Present"
              image={PIndigenous}
              altText="PIndigenous Logo"
            >
              <BulletList>
                <BulletListItem>
                  Lead PIndigenous, Pinterest&apos;s Indigenous ERG with 3 other
                  leads, focusing on Indigenous representation internally and
                  externally
                </BulletListItem>
                <BulletListItem>
                  Plan and activate different events across the year, working
                  with different teams across the company (Comms, Recruiting,
                  Talent Brand, Culinary, Product, and more)
                </BulletListItem>
                <BulletListItem>
                  Support and represent the company as an Indigenous
                  professional
                </BulletListItem>
              </BulletList>
            </ListItemExperience>
            <ListItemExperience
              title="Software Engineer I"
              company="Pinterest | February 2022 - June 2023"
              image={Pinterest}
              altText="Pinterest Logo"
            >
              <BulletList>
                <BulletListItem>
                  Develop and maintain the Pinterest ad creation and editing
                  tools
                </BulletListItem>
                <BulletListItem>
                  Work on various projects in Python and React to help improve
                  Advertiser experience
                </BulletListItem>
                <BulletListItem>
                  Take on on-call, code reviewer, and experiment reviewer
                  responsibilities
                </BulletListItem>
              </BulletList>
            </ListItemExperience>
            <ListItemExperience
              title="Software Engineering Intern"
              company="Pinterest | Summer 2021"
              image={Pinterest}
              altText="Pinterest Logo"
            >
              <BulletList>
                <BulletListItem>
                  Created an external data processing tool using Python and
                  React (still in use)
                </BulletListItem>
                <BulletListItem>
                  Worked with security, API, and design teams to craft a fully
                  functioning product
                </BulletListItem>
                <BulletListItem>
                  Increased follow-through metrics on an existing feature
                </BulletListItem>
              </BulletList>
            </ListItemExperience>
            <ListItemExperience
              title="Cybersecurity Intern"
              company="Chevron | Summer 2020"
              image={Chevron}
              alt="Chevron Logo"
            >
              <BulletList>
                <BulletListItem>
                  Designed and prototyped an exception-based reporting process
                  for contractors in C#
                </BulletListItem>
                <BulletListItem>
                  Used Azure to build a practical solution
                </BulletListItem>
                <BulletListItem>
                  Would save $18,000+ in work hours compared to doing the manual
                  process
                </BulletListItem>
              </BulletList>
            </ListItemExperience>
            <ListItemExperience
              title="Software Engineering Intern"
              company="GE Aviation | Summer 2019"
              image={GEAviation}
              alt="GE Aviation Logo"
            >
              <BulletList>
                <BulletListItem>
                  Worked in Excel to help semi-automate a manual process using
                  VBA (Visual Basic for Applications)
                </BulletListItem>
              </BulletList>
            </ListItemExperience>
          </List>
        )}
        {tabState === 2 && (
          <List>
            <ListItemExperience
              title="Webmaster"
              company="Bay Area American Indian Two Spirits (BAAITS) | November 2022 - Present"
              image={BAAITS}
            >
              <BulletList>
                <BulletListItem>
                  Maintain the Wix website for Bay Area American Indian Two
                  Spirits (BAAITS) by updating with current information on
                  events and programming
                </BulletListItem>
                <BulletListItem>
                  <a href="https://baaits.org" target="_blank" rel="noreferrer">
                    baaits.org
                  </a>
                </BulletListItem>
                <BulletListItem>
                  Revamped the home page and added pages for BAAITS sponsors,
                  the 2023 Powwow, Upcoming events, Calendar, and other much
                  needed pages
                </BulletListItem>
                <BulletListItem>
                  Have also volunteered physically and with other tech related
                  tasks
                </BulletListItem>
              </BulletList>
            </ListItemExperience>
            <ListItemExperience
              title="Webmaster"
              company="OU AISES Chapter | April 2020 - December 2021"
              image={AISES}
              altText="AISES Logo"
            >
              <BulletList>
                <BulletListItem>
                  Revamped and maintained the Wordpress website for the
                  University of Oklahoma&apos;s American Indians in Science and
                  Engineering Society (AISES) chapter
                </BulletListItem>
                <BulletListItem>
                  AISES promotes the inclusion of Indigenous people in STEM
                  fields in the U.S. and Canada
                </BulletListItem>
                <BulletListItem>
                  Have maintained active membership since 2018 and am now a
                  lifetime member
                </BulletListItem>
              </BulletList>
            </ListItemExperience>
          </List>
        )}
        {tabState === 3 && (
          <ListItemExperience
            title="Student"
            company="University of Oklahoma | August 2018 - December 2021"
            image={OU}
            alt="University of Oklahoma Logo"
            state={true}
          >
            <BulletList>
              <BulletListItem>
                Bachelor&apos;s Degree in Computer Science
              </BulletListItem>
              <BulletListItem>3.72 GPA</BulletListItem>
            </BulletList>
          </ListItemExperience>
        )}
        {tabState === 4 && (
          <List>
            <ListItemExperience
              title="Pinterest Makeathon Crowd Favorite Award"
              company="2022"
              image={Pinterest}
              altText="Pinterest Logo"
            >
              <BulletList>
                <BulletListItem>
                  Makeathon is an internal hackathon at Pinterest centered
                  around implementing new ideas that employees want to see. Many
                  of them make it into the roadmap for next year
                </BulletListItem>
                <BulletListItem>
                  My team&apos;s project won the Crowd Favorite Award, which is
                  bestowed to the project that the most employees voted for. We
                  had over 400 votes for our project
                </BulletListItem>
              </BulletList>
            </ListItemExperience>
            <ListItemExperience
              title="National Cyber League Cybersecurity Competition"
              company="2022"
              image={CyberSkyline}
              alt="National Cyber League Logo"
            >
              <BulletList>
                <BulletListItem>
                  Cyber Skyline is a Cybersecurity competition with challenges
                  and categories based on cybersecurity skills.
                </BulletListItem>
                <BulletListItem>
                  Placed top 50 (44th) for the individual competition in 2021
                  Fall
                </BulletListItem>
                <BulletListItem>
                  Placed top 30 (27th) for the team competition in 2021 Fall
                </BulletListItem>
                <BulletListItem>
                  <a
                    href="https://cyberskyline.com/hosted_events/ncl-fall-2021"
                    target="_blank"
                    rel="noreferrer"
                  >
                    2021 Fall Leaderboard
                  </a>
                </BulletListItem>
              </BulletList>
            </ListItemExperience>
            <ListItemExperience
              title="AISES National Conference Hackathon Winner"
              company="2020"
              image={AISES}
              altText="AISES Logo"
            >
              <BulletList>
                <BulletListItem>
                  Won the AISES (American Indians in Science and Engineering
                  Society) National Conference Hackathon again
                </BulletListItem>
              </BulletList>
            </ListItemExperience>
            <ListItemExperience
              title="AISES National Conference Hackathon Winner"
              company="2019"
              image={AISES}
              altText="AISES Logo"
            >
              <BulletList>
                <BulletListItem>
                  Won the AISES (American Indians in Science and Engineering
                  Society) National Conference Hackathon
                </BulletListItem>
              </BulletList>
            </ListItemExperience>
            <ListItemExperience
              title="National Merit Scholar (Finalist)"
              company="2018"
              image={NationalMerit}
              alt="National Merit Scholarship Corporation Logo"
            >
              <BulletList>
                <BulletListItem>
                  The National Merit Scholarship Corporation chooses less than
                  16,000 students (less than 1% of graduating seniors in the
                  United States) to be National Merit Scholars. Your PSAT score
                  must be extremely high to qualify in addition to other
                  requirements, essentially a very high performing student.
                </BulletListItem>
                <BulletListItem>
                  <a
                    href="https://www.nationalmerit.org/s/1758/interior.aspx?sid=1758&gid=2&pgid=1881"
                    target="_blank"
                    rel="noreferrer"
                  >
                    National Merit Scholar Qualifications
                  </a>
                </BulletListItem>
              </BulletList>
            </ListItemExperience>
          </List>
        )}
      </div>
    </>
  );
}

CV.Layout = Layout;
export default CV;
